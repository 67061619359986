import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  static values = {
    authorId: String,
    createdAt: String,
    chatItemId: String,
    payload: Object
  }

  connect() {
    this.determineSameAuthor()
  }

  get prevChannelItem() {
    const previous = this.element.previousElementSibling
    if (previous && previous.classList.contains('chat-item')) {
      return previous
    } else {
      return null
    }
  }

  determineSameAuthor(){
    const previous = this.prevChannelItem
    if(previous && previous.dataset.channelItemAuthorIdValue === this.authorIdValue){
      this.element.classList.add('same-author')
    }
  }

  deleteItem(){
    const detail = {
      createdAt: this.createdAtValue,
      id: this.chatItemIdValue,
      authorId: this.authorIdValue
    }
    this.dispatchEvent('deleteChannelItem', detail);
  }

  forwardItem(){
    const detail = this.payloadValue
    this.dispatchEvent('forwardChannelItem', detail);
  }

  dispatchEvent(eventName, detail){
    const event = new CustomEvent(eventName, {bubbles: true, cancelable: true, detail})
    this.element.dispatchEvent(event);
  }
}